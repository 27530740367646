import { initializeApp } from 'firebase/app';
import { initializeFirestore } from 'firebase/firestore';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

const config = {
    apiKey: process.env.GATSBY_FIREBASE_WEB_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
};

let firebaseInstance;

export const getFirebase = (UID) => {
    if (firebaseInstance) {
        return firebaseInstance;
    }

    const app = initializeApp(config);
    const auth = getAuth(app);
    signInWithCustomToken(auth, UID).catch(function (error) {
        console.warn(error.message);
    });

    const firestore = initializeFirestore(app, {
        ignoreUndefinedProperties: true,
    });

    firebaseInstance = { app, auth, firestore };

    return firebaseInstance;
};
