import React from 'react';

const FirebaseContext = React.createContext(null);

export const withFirebase = Component => props => {
    const context = React.useContext(FirebaseContext);

    if (context) {
        const { firebase, shop, token, host, user_id } = context;
        return (
            <Component {...props} firebase={firebase} shop={shop} token={token} host={host} user_id={user_id} />
        );
    } else {
        return (
            <div><p>No context</p></div>
        );
    }
};

export default FirebaseContext;
